<template>
  <div class="card">
    <div class="card-body">
      <h4 class="header-title mt-0 mb-3">Wing Information</h4>
      <div class="text-left">
        <p class="text-muted">
          <strong>Serial : </strong>
          <span class="ml-2">{{ wingInfo.serial_no }}</span>
        </p>
        <p>
          <strong>Vendor : </strong>
          <span class="ml-2"
            >{{ wingInfo.operator.company_name }} |
            {{ wingInfo.operator.zone.name }}</span
          >
        </p>
        <p>
          <strong>Operational Weight range : </strong>
          <span class="ml-2"
            >{{ wingInfo.operational_weight_min }} kg -
            {{ wingInfo.operational_weight_max }} kg</span
          >
        </p>
        <p>
          <strong>Glider Weight : </strong>
          <span class="ml-2">{{ wingInfo.glider_weight }} kg</span>
        </p>
        <p>
          <strong>Color : </strong>
          <span class="ml-2">{{ wingInfo.wing_color }}</span>
        </p>
        <p>
          <strong>EN Certification : </strong>
          <span class="ml-2">{{ wingInfo.en_certification }}</span>
        </p>
        <p>
          <strong>Year of Manufacture : </strong>
          <span class="ml-2">{{ wingInfo.year_mfg }}</span>
        </p>
        <p>
          <strong>Last Inspedted On : </strong>
          <span class="ml-2">{{ wingInfo.last_inspected_by || "--" }}</span>
        </p>
        <p>
          <strong>Last Inspedted By : </strong>
          <span class="ml-2">{{ wingInfo.last_inspected_date || "--" }}</span>
        </p>
        <hr />
        <p>
          <strong>Pilots flying this Wing : </strong>
        </p>
        <ul class="equipment-users">
          <li v-for="pilot in wingInfo.operator.pilots" :key="pilot.user.name">
            <span class="float-left m-1">
              <img
                :src="getImageUrl(pilot.user.avatar)"
                :title="pilot.user.name"
                class="rounded-circle img-thumbnail"
              />
            </span>
          </li>
        </ul>
      </div>
    </div>
    <!-- end card-body -->
  </div>
  <!-- end card -->
</template>
<script>
export default {
  name: "wingCard",
  props: {
    wingInfo: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    getImageUrl(url) {
      return url ? url : "/img/default-avatar.jpg";
    },
  },
};
</script>
<style lang="scss" scoped>
ul.equipment-users {
  list-style-type: none;
  padding: 0;

  img.rounded-circle.img-thumbnail {
    width: 50px;
    height:50px;
    display:block;
    overflow:hidden;
  }
}
</style>
