<template>
  <div class="card flight" v-if="flights">
    <div class="mx-1 my-2">
      <b-table
        :fields="fields"
        :items="flights"
        :per-page="perPage"
        :current-page="currentPage"
        show-empty
        striped
        responsive
        class="orders-list"
      >
        <!-- flight ID -->
        <template v-slot:cell(flight_id)="row">
          &#x23;{{ row.value }}
        </template>
        <!-- flight time -->
        <template v-slot:cell(flight_time)="row">
          {{ formatDate(row.value) }}
          <small class="start-time">{{ formatTime(row.value) }}</small>
        </template>
        <!-- flight type -->
        <template v-slot:cell(flight_type)="row">
          {{ row.value }}
        </template>
        <template v-slot:cell(actions)>
          <a title="View" class="action-icon view">
            <i class="mdi mdi-eye"></i>
          </a>
        </template>
      </b-table>
    </div>
    <div class="row mx-2">
      <!-- # of entries per page selector -->
      <div class="col-12 col-sm-6 text-center text-sm-left mb-2 mb-sm-0">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="ml-2 mr-2"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="dataTables_paginate paging_simple_numbers">
          <b-pagination
            v-model="currentPage"
            class="justify-content-center justify-content-sm-end"
            :total-rows="tRow"
            :per-page="perPage"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DateTime } from "luxon";
export default {
  name: "flightWing",
  props: {
    flights: {
      type: Array,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fields: [
        {
          key: "flight_id",
          label: "Flight ID",
          class: "flight-id",
          sortable: true,
        },
        {
          key: "flight_time",
          label: "Date",
          class: "data-time",
          sortable: true,
        },
        {
          key: "flight_type",
          label: "Flight Type",
          class: "flight-type",
          sortable: true,
        },
        { key: "actions", class: "action-links" },
      ],
    };
  },
  computed: {
    tRow() {
      return this.flights.length;
    },
  },
  methods: {
    formatDate(date) {
      // HACK: wp rest api timezone data is misleading and not adjusted for timezone.
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATE_FULL);
    },
    formatTime(date) {
      // HACK: wp rest api timezone data is misleading and not adjusted for timezone.
      return DateTime.fromISO(date).toUTC().toFormat("h:mm a");
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .no-padding {
    padding: 0;
  }

  table.table {
    color: #6c757d;

    .flight-id {
      font-weight: bold;
      min-width: 120px;
    }

    .flight-type {
      min-width: 150px;
      font-weight: bold;
    }

    .start-time {
      min-width: 150px;
      font-weight: bold;
      display: block;
    }

    .action-icon {
      display: inherit;
      padding: 0 5px;
      font-size: 1.2rem;
      color: #98a6ad;
      cursor: pointer;
    }

    .action-icon:hover {
      color: #6c757d;
    }
  }
}
</style>
