<template>
  <div class="media">
    <span class="float-left m-2 mr-4">
      <img
        src="/img/default-avatar.jpg"
        alt=""
        class="rounded-circle img-thumbnail"
      />
    </span>
    <div class="media-body" v-if="wingflights.length > 0">
      <h4 :class="`mt-1 mb-1 text-${textColor}`">
        {{ wingInfo.equipment_model.brand }} {{ wingInfo.equipment_model.name }}
      </h4>
      <p :class="`mt-2 font-19 text-${textColor}`">
        S#
        {{ wingInfo.serial_no }}
        | {{ wingInfo.operational_weight_min }}kg -
        {{ wingInfo.operational_weight_max }} kg
      </p>
      <ul :class="`mb-0 list-inline text-${textColor}`">
        <li class="list-inline-item mr-3">
          <h5 class="mb-1">-</h5>
          <p :class="`mb-0 font-13 text-${textColor}-50`">Hours (approx)</p>
        </li>
        <li class="list-inline-item">
          <h5 class="mb-1">{{ wingflights.length }}</h5>
          <p :class="`mb-0 font-13 text-${textColor}-50`">Number of Flights</p>
        </li>
      </ul>
    </div>
  </div>
  <!-- end media -->
</template>
<script>
//import { bus } from "@/main";
export default {
  name: "profileWing",
  props: {
    textColor: {
      type: String,
      default: "white",
    },
    wingflights: {
      type: Array,
    },
    wingInfo: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    getImageUrl() {
      return "/img/default-avatar.jpg";
    },
  },
};
</script>
<style lang="scss" scoped>
.media {
  img.rounded-circle.img-thumbnail {
    width: 100px;
    height: 100px;
    display: block;
    overflow: hidden;
  }
}
</style>
